// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-it-js": () => import("./../src/pages/about.it.js" /* webpackChunkName: "component---src-pages-about-it-js" */),
  "component---src-pages-ecommerce-en-js": () => import("./../src/pages/ecommerce.en.js" /* webpackChunkName: "component---src-pages-ecommerce-en-js" */),
  "component---src-pages-ecommerce-it-js": () => import("./../src/pages/ecommerce.it.js" /* webpackChunkName: "component---src-pages-ecommerce-it-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-it-js": () => import("./../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-lookbook-en-js": () => import("./../src/pages/lookbook.en.js" /* webpackChunkName: "component---src-pages-lookbook-en-js" */),
  "component---src-pages-lookbook-it-js": () => import("./../src/pages/lookbook.it.js" /* webpackChunkName: "component---src-pages-lookbook-it-js" */),
  "component---src-pages-post-production-en-js": () => import("./../src/pages/post-production.en.js" /* webpackChunkName: "component---src-pages-post-production-en-js" */),
  "component---src-pages-post-production-it-js": () => import("./../src/pages/post-production.it.js" /* webpackChunkName: "component---src-pages-post-production-it-js" */),
  "component---src-pages-social-media-project-en-js": () => import("./../src/pages/social-media-project.en.js" /* webpackChunkName: "component---src-pages-social-media-project-en-js" */),
  "component---src-pages-social-media-project-it-js": () => import("./../src/pages/social-media-project.it.js" /* webpackChunkName: "component---src-pages-social-media-project-it-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-making-en-js": () => import("./../src/pages/video-making.en.js" /* webpackChunkName: "component---src-pages-video-making-en-js" */),
  "component---src-pages-video-making-it-js": () => import("./../src/pages/video-making.it.js" /* webpackChunkName: "component---src-pages-video-making-it-js" */),
  "component---src-pages-virtual-showroom-en-js": () => import("./../src/pages/virtual-showroom.en.js" /* webpackChunkName: "component---src-pages-virtual-showroom-en-js" */),
  "component---src-pages-virtual-showroom-it-js": () => import("./../src/pages/virtual-showroom.it.js" /* webpackChunkName: "component---src-pages-virtual-showroom-it-js" */)
}

